import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import "./about.scss";

const maxSymbols = 347;

export default function About(props) {
  const { text, isMobile } = props;

  const [preparedText, setPreparedText] = useState(text);

  useEffect(() => {
    prepareText();
  }, [text, isMobile]);

  const prepareText = () => {
    const cropedText = text.substring(0, maxSymbols) + "...";
    // setPreparedText(isMobile ? cropedText : text);
    setPreparedText(cropedText);
  };

  const showFullText = () => {
    setPreparedText(text);
  };

  return (
    <div id="about" className="about">
      {!isMobile && (
        <div className="about-image-block">
          <img src="./assets/imgs/about/man_glass.png" alt="" />
        </div>
      )}
      <div className="about-text-block">
        <p className="about-title">О фестивале</p>
        <div className="about-text">
          {parse(preparedText)}
          {text.length !== preparedText.length && (
            <div onClick={showFullText} className="about-readmore">
              Читать далее
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
