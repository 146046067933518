import React from "react";
import "./partners.scss";

export default function Partners(props) {
  const { partners } = props;
  return (
    <div id="partners" className="partners">
      <p className="partners-title">Партнеры фестиваля</p>
      <div className="partners-grid">
        {partners.map((partner, index) => (
          <img
            className="partners-partner"
            key={index}
            src={partner.logo}
            alt={partner.link}
            onClick={() => window.open(partner.link, "_blank")}
          />
        ))}
      </div>
    </div>
  );
}
