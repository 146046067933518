import React from "react";
import Slider from "react-slick";
import "./guests.scss";

export default function Guests(params) {
  const { guests, isMobile } = params;

  const slider_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    fade: isMobile,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
  };

  return (
    <div id="guests" className="guests">
      <p className="guests-title">Гости фестиваля</p>
      <div className="guests-slider">
        <Slider {...slider_settings}>
          {guests.map((guest, index) => (
            <div key={index}>
              <div className="guest-card">
                <img
                  className="guest-photo"
                  src={guest.photo}
                  alt={guest?.alt}
                />
                <p className="guest-name">{guest.name}</p>
                <p className="guest-bio">{guest.bio}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
