import React from "react";
import Slider from "react-slick";
import "./ogrs.scss";

export default function Orgs(props) {
  const { orgs, isMobile } = props;

  const slider_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    fade: isMobile,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
  };

  return (
    <div id="orgs" className="orgs">
      <p className="orgs-title">Организаторы</p>
      <div className="orgs-slider">
        <Slider {...slider_settings}>
          {orgs.map((org, index) => (
            <div key={index}>
              <div className="org-card">
                <img className="org-photo" src={org.photo} alt={org.name} />
                <p className="org-name">{org.name}</p>
                <p className="org-bio">{org.bio}</p>
                <p className="org-info">{org.info}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
