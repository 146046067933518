import React, { useEffect, useRef, useState } from "react";
import "./header.scss";

export default function Header(props) {
  const { isMobile } = props;
  const headerRef = useRef(null);
  const [showUpBtn, setShowUpBtn] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", onWindowScroll);

    return () => {
      window.removeEventListener("scroll", onWindowScroll);
    };
  }, []);

  const onWindowScroll = (e) => {
    if (headerRef.current) {
      if (window.scrollY > headerRef.current.offsetHeight) {
        setShowUpBtn(true);
      } else {
        setShowUpBtn(false);
      }
    }
  };

  return (
    <>
      <div
        className={`up-btn ${showUpBtn ? "show" : "hide"}`}
        style={{
          backgroundImage: isMobile
            ? "url(./assets/imgs/header/up_btn_mobile.png)"
            : "url(./assets/imgs/header/up_btn.png)",
        }}
        onClick={() => window.scrollTo(0, 0)}
      />
      <div
        className="header"
        style={{
          backgroundImage: isMobile
            ? "url(/assets/imgs/header/header_bg_mobile.png)"
            : "url(/assets/imgs/header/header_bg.png)",
        }}
        ref={headerRef}
      >
        {isMobile && (
          <div
            className={`header-menu-btn${showMenu ? " hide" : " show"}`}
            style={{
              backgroundImage: "url(./assets/imgs/header/menu_bars.png)",
            }}
            onClick={() => setShowMenu(true)}
          />
        )}
        <div className={`header-nav-block${showMenu ? " show" : " hide"}`}>
          {isMobile && (
            <div
              className="header-close-btn"
              style={{
                backgroundImage: "url(./assets/imgs/header/close_menu.png)",
              }}
              onClick={() => setShowMenu(false)}
            />
          )}
          <nav className="header-nav-block_links">
            <a className="header-nav-block_link" href="#about">
              О фестивале
            </a>
            <a className="header-nav-block_link" href="#program">
              Программа
            </a>
            <a className="header-nav-block_link" href="#guests">
              Гости
            </a>
            <a className="header-nav-block_link" href="#orgs">
              Организаторы
            </a>
            <a className="header-nav-block_link" href="#events">
              Мероприятия
            </a>
            {/*<a className="header-nav-block_link" href="#postcross">*/}
            {/*  Посткроссинг*/}
            {/*</a>*/}
            <a className="header-nav-block_link" href="#partners">
              Партнёры
            </a>
            <a className="header-nav-block_link" href="#contacts">
              Контакты
            </a>
          </nav>
        </div>

        <div className="header-main-block">
          <div className="header-main-block_left-side">
            <img
              className="header-main-block_logo"
              src="/assets/imgs/header/festival_logo.png"
              alt="Чеховский фестиваль"
            />
          </div>

          <div className="header-main-block_right-side">
            {!isMobile && (
              <div className="header-title-block">
                <p className="header-title-block_t1">чеховский фестиваль</p>
                <p className="header-title-block_t2">
                  <span className="header-title-block_t2__wide">остров</span>
                  <br />
                  Сахалин
                </p>
              </div>
            )}
            <div className="header-pfki-block">
              <img
                src="./assets/imgs/header/header_logo.png"
                alt="Президнсткий фонд культурных инициатив"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
