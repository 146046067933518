import React from "react";
import "./footer.scss";

export default function Footer(props) {
  const { tg, vk, ok } = props.links;

  return (
    <div id="contacts" className="footer">
      <p className="footer-title">Контакты</p>
      <div className="footer-content">
        <div className="footer-socials">
          <p className="footer-socials_text">Чеховский фестиваль в соцсетях:</p>
          <div className="footer-socials_links">
            <a
              className="footer-socials_link"
              href={tg}
              target="_blank"
              rel="noreferrer"
            >
              <img src="./assets/imgs/contacts/tg-icon.png" alt="" />
            </a>
            <a
              className="footer-socials_link"
              href={vk}
              target="_blank"
              rel="noreferrer"
            >
              <img src="./assets/imgs/contacts/vk-icon.png" alt="" />
            </a>
            <a
              className="footer-socials_link"
              href={ok}
              target="_blank"
              rel="noreferrer"
            >
              <img src="./assets/imgs/contacts/ok-icon.png" alt="" />
            </a>
          </div>
        </div>
        <div className="footer-map-block">
          <div className="footer-map_left">
            <p>Города - участники Чеховского фестиваля</p>
            <img src="./assets/imgs/contacts/map_01.png" alt="" />
          </div>
          <div className="footer-map_right">
            <p>Место проведения Чеховского фестиваля в Южно-Сахалинске</p>
            <img src="./assets/imgs/contacts/map_02.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}
