import { useEffect, useState } from "react";

import "./App.scss";

import Header from "./components/header/header";
import About from "./components/about/about";
import Program from "./components/program/program";
import Partners from "./components/partners/partners";
import Guests from "./components/guests/guests";
import Events from "./components/events/events";
import Orgs from "./components/orgs/orgs";
import Footer from "./components/footer/footer";
import Postcross from "./components/postcross/postcross";

function App() {
  const [data, setData] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    mobileCheck();

    window.addEventListener("resize", mobileCheck);

    return () => {
      window.removeEventListener("resize", mobileCheck);
    };
  }, []);

  const mobileCheck = () => {
    if (window.innerWidth < 1000) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  const getData = async () => {
    fetch("./assets/params.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then(function (response) {
        return response.json();
      })
      .then(function (params) {
        setData(params);
      });
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className={`app-wrapper${isMobile ? " mobile" : ""}`}>
      <Header isMobile={isMobile} />
      <About isMobile={isMobile} text={data == null ? "" : data.about.text} />
      <Program text={data == null ? "" : data.program.text} />
      <Guests isMobile={isMobile} guests={data == null ? [] : data.guests} />
      <Orgs isMobile={isMobile} orgs={data == null ? [] : data.orgs} />
      <Events isMobile={isMobile} events={data == null ? [] : data.events} />
      {/*<Postcross*/}
      {/*  isMobile={isMobile}*/}
      {/*  postLink={data == null ? [] : data.postLink}*/}
      {/*/>*/}
      <Partners partners={data == null ? [] : data.partners} />
      <Footer links={data == null ? [] : data.contacts.links} />
    </div>
  );
}

export default App;
