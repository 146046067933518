import React, { useEffect, useState } from "react";
import parse from "html-react-parser";
import "./program.scss";

const maxSymbols = 164;

export default function Program(props) {
  const { text } = props;
  const [preparedText, setPreparedText] = useState(text);

  useEffect(() => {
    prepareText();
  }, [text]);

  const prepareText = () => {
    const cropedText = text.substring(0, maxSymbols) + "...";
    setPreparedText(cropedText);
  };

  const showFullText = () => {
    setPreparedText(text);
  };

  const downloadPdf = () => {
    window.open("./assets/docs/program.pdf", "_blank");
  };

  return (
    <div
      id="program"
      className="program"
      style={{ backgroundImage: "url(./assets/imgs/dark_bg.png)" }}
    >
      <p className="program-title">Программа</p>
      <div className="program-content">
        <div className="program-text-block">
          {parse(preparedText)}
          {text.length !== preparedText.length && (
            <div onClick={showFullText} className="program-readmore">
              Читать далее
            </div>
          )}
        </div>
        <a
            href='./assets/docs/program.pdf'
            target='_blank'
          className="program-pdf-block"
          // onClick={downloadPdf}
          title="Скачать PDF"
        >
          <img
            className="program-pdf"
            src="./assets/imgs/program/pdf.png"
            alt=""
          />
        </a>
      </div>
    </div>
  );
}
