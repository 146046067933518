import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import "./events.scss";
import parse from "html-react-parser";

const maxSymbols = 70;

export default function Events(props) {
  const { events, isMobile } = props;
  let eventsNew = JSON.parse(JSON.stringify(events));

  const [preparedEvents, setPreparedEvents] = useState(eventsNew);

  useEffect(() => {
    prepareText();
  }, [events]);

  const prepareText = () => {
    eventsNew = eventsNew.map((item) => {
      item.text = item.text.length > 3 ? item.text.substring(0, maxSymbols) + "..." : item.text;
      return item;
    });

    setPreparedEvents(eventsNew);
  };

  const showFullText = () => {
    setPreparedEvents(eventsNew);
  };

  const slider_settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 500,
    fade: isMobile,
    slidesToShow: isMobile ? 1 : 3,
    slidesToScroll: 1,
  };

  return (
    <div
      id="events"
      className="events"
      style={{ backgroundImage: "url(./assets/imgs/dark_bg.png)" }}
    >
      <p className="events-title">мероприятия</p>
      <div className="events-slider">
        <Slider {...slider_settings}>
          {events.map((event, index) => (
            <div key={index}>
              <div className="event-card">
                {event.img && <img className="event-img" src={event.img} alt="" />}
                {!event.img && <span className="event-img-empty" />}
                <p className="event-title">{event.title}</p>
                <p className="event-text">{parse(preparedEvents[index]?.text ?? '')}</p>

                {event.text.length !== preparedEvents[index]?.text?.length && (
                    <div onClick={showFullText} className="program-readmore">
                      Читать далее
                    </div>
                )}
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}
